<template>
  <section>
    <match-progress></match-progress>
    <b-card>
      <b-row>
        <b-col sm="6">
          <h2 class="mb-0">
            Live Matches
          </h2>
        </b-col>
        <b-col sm="6">
          <div class="d-flex align-items-end justify-content-end">
            <b-dropdown
              variant="link"
              no-caret
              toggle-class="p-0"
              right
            >
              <template #button-content>
                <b-button 
                  variant="outline-primary"
                  class="ml-2"
                  :disabled="!rows.length"
                >
                  <feather-icon
                    size="16"
                    icon="DownloadIcon"  
                    class="mr-50"
                  />
                  <span class="text-nowrap">Export</span>
                </b-button>
              </template>

              <b-dropdown-item @click="onDownloadMatch('csv')">
                <span class="align-middle ml-50">Export CSV</span>
              </b-dropdown-item>

              <b-dropdown-item @click="onDownloadMatch('xlsx')">
                <span class="align-middle ml-50">Export XLSX</span>
              </b-dropdown-item>
            </b-dropdown>
            <b-dropdown
              variant="link"
              no-caret
              toggle-class="p-0"
              right
            >
              <template #button-content>
                <b-button
                  variant="outline-primary"
                  class="ml-2"
                  :disabled="!rows.length"
                >
                  <feather-icon
                    size="16"
                    icon="DownloadIcon"
                    class="mr-50"
                  />
                  <span class="text-nowrap">Formatted Export</span>
                </b-button>
              </template>

              <b-dropdown-item @click="onDownloadMatch('csv', 'formatted')">
                <span class="align-middle ml-50">Formatted Export CSV</span>
              </b-dropdown-item>

              <b-dropdown-item @click="onDownloadMatch('xlsx', 'formatted')">
                <span class="align-middle ml-50">Formatted Export XLSX</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-col>
      </b-row>
      <vue-good-table
        class="mt-3"
        mode="remote"
        v-if="rows.length > 0"
        :is-loading="isLoading"
        :columns="columns"
        :rows="rows"
        :rtl="isRtl"
        :search-options="{
          enabled: false,
        }"
        :select-options="{ 
          enabled: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'bulk-selection-wrapper',
          selectionText: 'Matches selected',
          clearSelectionText: 'Clear Selections',
        }"
        :pagination-options="{
          enabled: true,
          perPage: perPage,
        }"
        style-class="vgt-table striped"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        @on-selected-rows-change="bulkSelectionChange"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'status'">  
              <b-badge :variant="STATUS_COLOR[props.row.status.name]" :id="`status-target-${props.row.id}`" :class="props.row.status.name === 'Cancelled' ? 'cursor-pointer' : ''">
                {{ props.row.status.name }}
              </b-badge>
              <b-popover v-if="props.row.status.name === 'Cancelled'" :target="`status-target-${props.row.id}`" triggers="hover" placement="top">
                <p>Closed by <br>{{ props.row.closed_by.full_name }}</p>
              </b-popover>    
          </span>
          <span v-if="props.column.field === 'compat'">
            <b-link
              href="#"
              class="compare-user d-flex align-items-center"
              @click="openCompareModal(props.row)"
            >
            <b-progress
                class=""
                :value="props.row.compatibility"
                variant="primary"
              />{{props.row.compatibility}}%
            </b-link>
          </span>
          <div
            v-if="props.column.field === 'action'"
            cols="12"
            md="2"
            class="justify-content-between flex-wrap vgt-center-align"
          >
            <div class="align-items-center mb-0 mt-0">
              <b-button 
              class="btn-icon"
              variant=" btn-outline"
              :id="`popover-target-${props.row.id}`">
                <feather-icon
                  icon="BarChartIcon"
                  size="20"
                />
              </b-button>
              <b-popover :target="`popover-target-${props.row.id}`" triggers="hover" placement="top">
               
                <p class="mb-0">
                  <small><b>Surveys</b></small>
                </p>
                <p class="mb-50">
                  <small>{{ surveysCount(props.row) }}</small>
                </p>
                
                <p class="mb-0">
                  <small><b>Training</b></small>
                </p>
                <p class="mb-50">
                  <small>{{ trainingsCount(props.row) }}</small>
                </p>

                <p class="mb-0">
                  <small><b>Duration</b></small>
                </p>
                <p class="mb-50">
                  <small>{{ formatDuration(props.row) }}</small>
                </p>
              </b-popover>
              <b-button
                variant="outline-none"
                class="nav action-menu"
    
              >
                <b-nav-item-dropdown
                  class="btn-icon"
                >
                  <template 
                    #button-content
                  >
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="20"
                    />                
                  </template>

                  <action-dropdown-item
                    :to="{
                      name: 'champion-program-communications',
                      query: {
                        user_ids: props.row.match_users.map((u) => u.user_id),
                      },
                    }"
                    link-class="d-flex align-items-center"
                  >
                    <feather-icon
                      icon="MessageSquareIcon"
                      size="16"
                      class="mr-50"
                    />
                    <span>Message</span>
                  </action-dropdown-item>
                  <action-dropdown-item
                    link-class="d-flex align-items-center"
                    :disabled="props.row.status_id === matchStatus.CANCELLED"
                    @click="unpublishMatch(props.row.id, props.row)"
                  >
                    <feather-icon
                      size="16"
                      icon="DownloadCloudIcon"
                      class="mr-50"
                    />
                    <span>Unpublish</span>
                  </action-dropdown-item>
                  <b-dropdown-item
                    link-class="d-flex align-items-center"
                    :to="{ name: 'champion-match-view', params: { id: defaultProgramId, matchId: props.row.id } }"
                  >
                      <feather-icon
                      icon="EyeIcon"
                      size="20"
                      class="mr-50"
                    />
                    <span>View</span>
                  </b-dropdown-item>
                </b-nav-item-dropdown>
              </b-button>
            </div>
          </div>
          <div
            v-if="props.column.field === 'user.0.full_name'"
            cols="12"
            md="2"
            class="justify-content-between flex-wrap"
          >
            <div class="align-items-center mb-0 mt-0">
              <router-link
                :to="{
                  name: 'champion-program-participant',
                  params: { id: defaultProgramId, participantId: props.row.match_users[0].user_id },
                }"
                class="table-primary-link"
              >
                {{ menteeRoleInMatch(props.row) }}
              </router-link>
            </div>
          </div>
          <div
            v-if="props.column.field === 'user.1.full_name'"
            cols="12"
            md="2"
            class="justify-content-between flex-wrap"
          >
            <div class="align-items-center mb-0 mt-0">
              <router-link
                :to="{
                  name: 'champion-program-participant',
                  params: { id: defaultProgramId, participantId: props.row.match_users[1].user_id },
                }"
                class="table-primary-link"
              >
                {{ mentorRoleInMatch(props.row) }}
              </router-link>
            </div>
          </div>
          <!-- Column: Common -->
          <span v-else>
            <span v-if="props.column.field !== 'status'">{{ props.formattedRow[props.column.field] }}</span>
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <table-pagination
            :per-page="perPage"
            :total="total"
            @perPageChanged="
              (value) => props.perPageChanged({ currentPerPage: value })
            "
            @pageChanged="
              (value) => props.pageChanged({ currentPage: value })
            "
          />
        </template>
        <div slot="selected-row-actions">
          <matches-bulk-actions
            :matches="bulkSelection"
            :can-un-publish="true"
            @updated="loadItems"
          />
        </div>
      </vue-good-table>
      <match-alert v-else-if="!isLoading" 
        variant="success"
        show
        title="You have no matches that have initiated their mentoring journey"
        message="Published matches that have initiated their mentoring journey appear here"
        >
        </match-alert>
      <b-modal
        id="updating-modal"
        hide-footer
        hide-header
        centered
        no-close-on-backdrop
        no-close-on-esc
      >
        <div class="d-block text-center">
          <b-spinner
            medium
            variant="primary"
            class="mt-2"
          />
          <h5 class="mt-2">
            Updating...
          </h5>
        </div>
      </b-modal>

    </b-card>
    <div class="d-flex justify-content-between">
    <router-link
        :to="{ name: 'champion-matches-locked-matches'}"
        class="btn btn-primary btn-icon"
    >
      <feather-icon
        icon="ArrowLeftIcon"
      />
      Previous 
    </router-link>
       
            </div>
    <user-application-summary-modal
      ref="userAppSummary"
      :match-users="matchUsers"
      :compare-percentage="comparePercentage"
      @clear="clearModal"
    />
  </section>
</template>

<script>
import ActionDropdownItem from "@/views/components/common/ActionDropdownItem.vue";
import {
  BButton,
  BDropdownItem,
  BNavItemDropdown,
  BCard,
  BRow,
  BCol,
  BBadge,
  BModal,
  BSpinner,
  BLink,
  BDropdown,
  BProgress,
  BPopover
} from "bootstrap-vue";
import { GOODTABLE_ISO_DATETIME_INPUT_FORMAT, GOODTABLE_LOCALISED_DATE_FORMAT, GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT, PRIMARY_DATE_FORMAT, localDateStringOrDateRangeStringToUtcRange } from "@/libs/utils";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import { userRoles, matchStatusDisplaySelect, matchStatusDisplay, matchStatus } from '@models';
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import matchesService  from "@/services/matchesService";
import { mapGetters } from 'vuex';
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import { STATUS_COLOR, getFormatedStringFromDays } from "@/libs/utils";
import UserApplicationSummaryModal from './UserApplicationSummaryModal';
import MatchesBulkActions from "./actions/MatchesBulkActions.vue";
import { BE_API_URL } from "@/constants/app";
import useAomTableConfig from "@aom-core/useAomTableConfig.js";
import { roleAlias } from "@/@aom-core/utils/utils";
import MatchProgress from "@/views/apps/champion/matches/MatchProgress.vue";
import MatchAlert from "@/views/apps/champion/matches/MatchAlert.vue";


export default {
  components: {
    ActionDropdownItem,
    BCard,
    BRow,
    BCol,
    BButton,
    BNavItemDropdown,
    BDropdownItem,
    BBadge,
    BModal,
    BSpinner,
    BLink,
    VueGoodTable,
    TablePagination,
    UserApplicationSummaryModal,
    MatchesBulkActions,
    BDropdown,
    MatchProgress,
    BProgress,
    MatchAlert,
    BPopover
  },
  data() {
    return {
      isLoading: false,
      modalShow: false,
      bulkSelection: [],
      columns: [],
      rows: [],
      columnFilters: [],
      sort: [],
      options: {
        chart: {
          type: 'radialBar',
        },
        grid: {
          padding: {
            top: -15,
            bottom: -15
          }
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              showOn: "always",
              name: {
                show: false,
              },
              value: {
                offsetY: 5,
                offsetX: 1,
                color: "#111",
                fontSize: "15px",
                show: true
              }
            }
          }
        },
      },
      STATUS_COLOR,
      matchStatus,
      matchUsers: [],
      comparePercentage: 0
    };
  },
  computed: {
    ...mapGetters('programs',['defaultProgramId', 'defaultProgram']),
    isRtl() {
      return store.state.appConfig.isRTL;
    }
  },
  created() {
    this.columns = [
      {
        label: roleAlias(userRoles.MENTEE, this.defaultProgram),
        field: 'user.0.full_name',
        filterOptions: {
          enabled: true,
          placeholder: roleAlias(userRoles.MENTEE, this.defaultProgram),
          filterValue: ''
        },
        width: "10em",
      },
      {
        label: roleAlias(userRoles.MENTOR, this.defaultProgram),
        field: 'user.1.full_name',
        filterOptions: {
          enabled: true,
          placeholder: roleAlias(userRoles.MENTOR, this.defaultProgram),
          filterValue: ''
        },
        width: "10em",
      },
      {
        label: "Compatibility",
        field: "compat",
        filterOptions: {
          enabled: false,
          placeholder: "Compatibility",
          filterValue: ''
        },
        width: "9em",
      },
      {
        label: "Publish",
        field: "started_at",
        filterOptions: {
          enabled: false,
          placeholder: "Published",
          filterValue: ''
        },
        type: 'date',
        dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
        dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT,
        width: "8em",
        thClass: 'vgt-left-align',
        tdClass: "vgt-left-align",
      },
      {
        label: "Complete",
        field: "ended_at",
        filterOptions: {
          enabled: false,
          placeholder: "Completed",
          filterValue: ''
        },
        type: 'date',
        dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
        dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT,
        tdClass: "text-center",
        width: "8em",
        thClass: 'vgt-left-align',
        tdClass: "vgt-left-align",
      },
      {
        label: "Status",
        field: 'status',
        formatFn: this.formatProgramStatus,
        filterOptions: {
          enabled: true,
          placeholder: "All",
          filterDropdownItems: matchStatusDisplaySelect,
          filterValue: ''
        },
        width: "7em"
      },
      {
        field: 'action',
        width: "9em",
        sortable: false
      }
    ];
    const columnFilterOnQuery = this.$route.query.columnFilter || null;
    if (columnFilterOnQuery) {
      const columnIndex = this.columns.findIndex(c => c.field === columnFilterOnQuery.field);
      this.columns[columnIndex].filterOptions.filterValue = columnFilterOnQuery.value;
    }
    this.loadItems();
  },
  mounted() {
    // Allows target by name prefix is vgt-{field-name}
    let datePickers = [];
    datePickers.push(document.getElementsByName("vgt-started_at"));
    datePickers.push(document.getElementsByName("vgt-ended_at"));
    datePickers.forEach(function (input) {
      flatPickr(input, {
        enableTime: false,
        dateFormat: GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT, 
        allowInput: true,
        altInput: true,
        altFormat: PRIMARY_DATE_FORMAT,
        mode: "range",
      });
    });
  },
  methods: {
    clearModal () {
      this.matchUsers = [];
      this.comparePercentage = 0;
    },
    openCompareModal(args) {
      const { match_users, compatibility } = args;
      // eslint-disable-next-line camelcase
      this.matchUsers = match_users;
      this.comparePercentage = compatibility;
      this.$refs.userAppSummary.show();
    },
    formatProgramStatus (value) {
      const {id} = value;
      return matchStatusDisplay[id];
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        if (field === 'compat') {
          field = 'compatibility';
        }
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of [
        "status",
        "started_at",
        "ended_at",
        "user.0.full_name",
        "user.1.full_name",
        "compat",
      ]) {
        if (params.columnFilters[col]) {
          if(col === 'compat') {
            columnFilters.push({
              field: 'compatibility',
              value: params.columnFilters[col],
            });
          } else if (col === "started_at" || col === "ended_at") {
            const dateRange = localDateStringOrDateRangeStringToUtcRange(params.columnFilters[col]);
            if (dateRange) {
              columnFilters.push({
                field: col,
                value: dateRange,
              });
            }
          } else {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
          }
          
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },
    async loadItems() {
      try {
        this.isLoading = true;
        const response = await matchesService.getListPublishedCancelledCompletedMatches(this.defaultProgramId, {
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        });
        const { items, total }  = response.data;
        this.total = total;
        this.rows = items;
      
      } catch (e) {
        this.$toast(makeErrorToast(e));
      } finally {
        this.isLoading = false;
      } 
    },
    menteeRoleInMatch(matches) {
      if(matches && matches.match_users.length > 0) {
        const mentee = matches.match_users.filter(m => m.role_id === userRoles.MENTEE);
        if(mentee.length > 0) {
          return mentee[0].user ? mentee[0].user.full_name : "";
        }
      }
    },
    mentorRoleInMatch(matches) {
      if(matches && matches.match_users.length > 0) {
        const mentor = matches.match_users.filter(m => m.role_id === userRoles.MENTOR);
        if(mentor.length > 0) {
          return mentor[0].user ? mentor[0].user.full_name : "";
        }
      }
    },
    surveysCount (value) {
      return `${value.completed_surveys_count} / ${value.surveys_count}`;
    },
    trainingsCount(value) {
      return `${value?.completed_trainings_count ? value.completed_trainings_count : 0} / ${value?.trainings_count ? value.trainings_count : 0}`;
    },
    formatDuration(value) {
      if(Number(value.duration) === 0) {
        return "< 1d ";
      }
      return getFormatedStringFromDays(value.duration);
    },
    async unpublishMatch(matchId, match) {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to Unpublish this match? This will sever the match and cannot be undone. Matches that are over 30 days into a program will still be counted in your program match capacity. Do you wish to continue?', {
          title: 'Unpublish Match?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Unpublish Match',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            const data = { ...match, status_id: matchStatus.CANCELLED};
            try {
              this.$bvModal.show('updating-modal');
              const result = await matchesService.patchUpdateMatch(this.defaultProgramId, matchId, data);
              if (result) {
                this.$toast(makeSuccessToast('Match Unpublished successfully.'));
                this.loadItems();
              }
            } catch (e) {
              const { data } = e.response;
              this.$log.error(e);
              this.$toast(makeErrorToast(data.message));
            } finally {
              this.$bvModal.hide('updating-modal');
            }
          }
        });
    },
    bulkSelectionChange(args) {
      const { selectedRows } = args;
      if(Array.isArray(selectedRows)) {
        this.bulkSelection = selectedRows;
      }
    },
    onDownloadMatch(type, style) {
      let downloadLink =
        `${BE_API_URL}/programs/${this.$route.params.id}/matches/download/${type}`;

      if (style) {
        downloadLink += `/${style}`;
      }
      
      downloadLink +=
        `?search=${encodeURIComponent(
          JSON.stringify({
            page: this.page,
            columnFilters: this.columnFilters,
            sort: this.sort,
          })
        )}`;
      window.document.location = downloadLink;
    },
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();
    return {
      total, perPage, page
    };
  }
};
</script>

<style lang="scss">
@import '@/assets/scss/vue/libs/vue-good-table.scss';
.nav .dropdown-toggle:not(.active)::after {
  background-image: none !important;
}
.compatibility-chart {
  .apexcharts-canvas {
    width: 100% !important;
    .apexcharts-svg {
      width: 100%;
      .apexcharts-inner {
        transform: translate(0px, -15px)
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.action-menu {
  display: inline-block;
}
::v-deep .apexcharts-canvas {
    margin: auto;
  }
  .bulk-selection-wrapper {
  background: none;
  font-size: 14px;
  color: inherit;
}
::v-deep .vgt-selection-info-row{
  background: none;
  font-size: 14px;
  color: inherit;
}
::v-deep table.vgt-table td {
  vertical-align: middle
}
.progress{
    flex:1;
    margin-right: 10px;
  }
</style>